<template>
  <v-row style="margin-top: -120px">
    <v-col sm="12" lg="12" md="12" xs="12" style="min-height: 700px">
      <bar-chart
        width="100%"
        :key="key"
        report="payment_transactions_report"
        :chart-params="chartParams"
        :table-params="tableParams"
        style="min-height: 500px"
        :filters="filters"
        @row-clicked="openReceipt"
      />
    </v-col>

    <v-snackbar top v-model="snackbar.display">
      <div>{{ snackbar.message }}</div>
      <v-btn
        color="pink"
        class="float-right"
        text
        @click="snackbar.display = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="dialog" width="500">
      <v-card :loading="loading">
        <v-card-title
          class="text-h5 grey lighten-2"
          v-if="item && item.description"
        >
          {{ item.description }}
        </v-card-title>

        <v-card-text v-if="item">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Fullname</v-list-item-title>
              <v-list-item-subtitle>{{ item.fullname }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Transaction Type</v-list-item-title>
              <v-list-item-subtitle>{{
                item.transfer_type
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Payment Type</v-list-item-title>
              <v-list-item-subtitle>{{ item.gateway }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>From Amount</v-list-item-title>
              <v-list-item-subtitle
                >{{ item.from_currency_code }}
                {{ item.from_amount }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>To Amount</v-list-item-title>
              <v-list-item-subtitle
                >{{ item.to_currency_code }}
                {{ item.to_amount }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Time</v-list-item-title>
              <v-list-item-subtitle>{{ item.created_at }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="item.approver_fullname">
            <v-list-item-content>
              <v-list-item-title>Approved By</v-list-item-title>
              <v-list-item-subtitle>{{
                item.approver_fullname
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line v-if="item.approved_time">
            <v-list-item-content>
              <v-list-item-title>Approved At</v-list-item-title>
              <v-list-item-subtitle>{{
                item.approved_time
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="dialog = false"
            :disabled="loading || confirmationDialog"
          >
            Close
          </v-btn>
          <v-btn
            v-if="canApprove"
            color="primary"
            text
            @click="openApprovalDialog()"
            :loading="loading"
            :disabled="loading || confirmationDialog"
          >
            Approve
          </v-btn>
          <v-btn
            v-if="canConfirm"
            color="primary"
            text
            @click="confirmPayment"
            :loading="loading"
            :disabled="loading || confirmationDialog"
          >
            Confirm 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmationDialog" max-width="500px">
      <v-card :loading="loading">
        <v-card-title class="text-h5 grey lighten-2" v-if="item">
          Confirm
        </v-card-title>

        <v-card-text v-if="item">
          <form autocomplete="off">
            <v-text-field
              label="Approval Code"
              required
              name="passCode"
              v-model="passCode"
              type="password"
              autocomplete="new-password"
              clearable
            ></v-text-field>
          </form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="confirmationDialog = false"
            :disabled="loading"
          >
            Close
          </v-btn>
          <v-btn
            v-if="canApprove"
            color="primary"
            text
            @click="approve()"
            :loading="loading"
          >
            Approve
          </v-btn>
          <v-btn
            v-if="canConfirm"
            color="primary"
            text
            @click="confirmationDialog = false"
            :loading="loading"
          >
            Confirm 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const transactionsNamespace = createNamespacedHelpers("transaction");
const usersNamespace = createNamespacedHelpers("users");
import NAWECService from "@/services/nawec.service";
import PayService from "@/services/pay.service";

const BarChart = () => import("@/components/charts/bar/chart.vue");

export default {
  components: {
    BarChart,
  },
  computed: {
    ...transactionsNamespace.mapState({
      transactionType: (state) => state.transactionType,
    }),
    ...usersNamespace.mapState({
      wallet: (state) => state.wallet,
    }),
  },
  data() {
    return {
      snackbar: {
        display: false,
        message: null,
      },
      key: "payment-logs-report",
      dialog: false,
      confirmationDialog: false,
      item: null,
      drawer: null,
      loading: false,
      intent: null,
      canApprove: false,
      canConfirm: false,
      passCode: null,
      /* type: "DEFAULT", */
      filters: [
        {
          source: "list",
          itemText: "name",
          itemValue: "code",
          label: "Payment Type",
          column: "gateway",
          value: null,
          list: [
            {
              code: "manual-bank-payment",
              name: "Manual Bank Transfer",
            },
            {
              code: "trust-payment",
              name: "Trust Payment",
            },
            {
              code: "instant-bank-payment",
              name: "Instant Payment",
            },
            {
              code: "STRIPE",
              name: "Other Card Payment",
            },
            {
              code: "afrimoney-payment",
              name: "Afri Money",
            },
            {
              code: "qmoney-payment",
              name: "Q Money",
            },
          ],
        },
        {
          type: "combobox",
          source: "acl_users",
          itemText: "fullname_mobile",
          itemValue: "code",
          label: "Select User",
          column: "f_user_code",
          value: null,
          list: [],
        },
      ],
      chartParams: {
        title: "Payment Logs",
        x: "created_date",
        valueColumn: "total",
        labelColumn: "tenant",
        conditions: {},
        valueColumns: [
          { value: "total", text: "Total" },
          {
            value: "total_count",
            text: "Number Of Transactions",
            currencyKey: "from_currency_code",
          },
        ],
      },
      tableParams: {
        report: "payment_transactions_list",
        title: "Transactions",
        x: "created_date",
        valueColumn: "total",
        labelColumn: "tenant",
        conditions: {},
        valueColumns: [
          { value: "id", text: "ID" },
          { value: "fullname", text: "Fullname" },
          { value: "status", text: "Status" },
          { value: "transfer_type", text: "Type" },
          { value: "gateway", text: "Payment Type" },
          { value: "from_currency_code", text: "From Currency" },
          { value: "from_amount", text: "From Amount" },
          { value: "to_currency_code", text: "To Currency" },
          { value: "to_amount", text: "To Amount" },
          { value: "created_at", text: "Time" },
          { value: "approver_fullname", text: "Approver Fullname" },
          { value: "approved_time", text: "Approved Time" },
        ],
        exportColumns: [
          { field: "id", text: "ID" },
          { field: "fullname", text: "Fullname" },
          { field: "status", text: "Status" },
          { field: "transfer_type", text: "Type" },
          { field: "gateway", text: "Payment Type" },
          { field: "from_currency_code", text: "From Currency" },
          { field: "from_amount", text: "From Amount" },
          { field: "to_currency_code", text: "To Currency" },
          { field: "to_amount", text: "To Amount" },
          { field: "created_at", text: "Time" },
          { field: "approver_fullname", text: "Approver Fullname" },
          { field: "approved_time", text: "Approved Time" },
        ],
      },
    };
  },
  methods: {
    ...usersNamespace.mapActions(["aGetWalletList"]),
    ...transactionsNamespace.mapActions(["aLoadTransactionDetails"]),
    ...transactionsNamespace.mapMutations(["mSetTransactionType"]),
    reset() {
      const date = new Date().getTime();
      this.key = "payment-logs-report-" + date;
      this.dialog = false;
      this.confirmationDialog = false;
    },
    alert(message) {
      this.snackbar.message = message;
      this.snackbar.display = true;
    },
    async approve() {
      if (!this.passCode) {
        this.alert("Enter pass code");
        return;
      }
      this.loading = true;

      try {
        const payload = {
          secret: this.item.secret,
          code: this.passCode,
        };
        await PayService.approve(payload);
        // const data = response.data;

        this.reset();

        // console.log("data", data);
      } catch (error) {
        this.alert(error);
      }
      this.loading = false;
    },
    async confirmPayment() {
      this.loading = true;
      
      try {

        const payload = {
          secret: this.item.secret,
        };
        await PayService.confirmPayment(payload);
        this.reset();
        // 
      } catch(error) {
        //
        this.alert(error);
      }

      this.loading = false;
    },
    openApprovalDialog() {
      this.confirmationDialog = true;
    },
    openReceipt(item) {
      this.item = null;
      this.canApprove = false;
      this.canConfirm = false;
      if (item) {
        this.item = { ...item };
        this.dialog = true;
        const approvedStatuses = [
          "transactions-processed",
          "payment-processed",
          "payment-approved",
          "processing",
        ];
        if (
          item.gateway === "STRIPE" &&
          !approvedStatuses.includes(item.status)
        ) {
          this.canConfirm = true;
        } else if (!approvedStatuses.includes(item.status)) {
          this.canApprove = true;
        }
      }
      console.log("item", item);
      // this.$router.push({ name: 'send-money-payout-details', params: { secret: item.secret } })
    },
    async loadNAWECIntent(secret) {
      this.loading = true;
      try {
        const response = await NAWECService.getNawecIntent(secret);
        const data = response.data;
        this.intent = data;
        // console.log("data", data);
      } catch (error) {
        //
      }
      this.loading = false;
    },
  },
  beforeMount() {
    this.mSetTransactionType(this.type);
  },
  mounted() {
    // this.aGetWalletList();

    console.log("type", this.transactionType);
  },
};
</script>
